<template>
  <SmoothModal
    :model-value="isModalOpen"
    no-overflow
    @update:model-value="closeModal"
  >
    <template #title>
      {{ t('merchantPayments.createPaymentModal.title') }}
    </template>
    <template #description>
      <AppText opacity="0.5">
        {{ t('merchantPayments.createPaymentModal.description') }}
      </AppText>
    </template>
    <ActionForm
      class="create-payment-form"
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      @validate="onCreatePayment"
      @error="onError"
    >
      <template #default="{ errors, action }">
        <SelectClient
          v-if="modelValue"
          :key="modelValue"
          v-model:email="requestFields.clientEmail"
          v-model:name="requestFields.clientName"
          v-model:status="clientStatus"
          v-model:is-new-client="isNewClient"
          :options="clients"
          :errors="errors"
          :response-errors="localizedErrors"
          @get-clients="onGetClients"
        />

        <AppText
          is-block
          type="uppercase"
          size="11px"
          class="font-medium"
          pb="10px"
          color="var(--color-black-04)"
        >
          {{ t('merchantPayments.createPaymentModal.label.amount') }}
        </AppText>
        <AmountInput
          v-model="requestFields.baseAmount"
          :error="errors.baseAmount"
          :placeholder="minAmount"
          :currency="currency.value"
          :number-format="numberFormat"
        />

        <FInput
          v-model="requestFields.memo"
          class="memo"
          type="textarea"
          :placeholder="t('merchantPayments.createPaymentModal.label.memo')"
          maxlength="121"
          :validation-error="errors.memo"
        />

        <AutoRevoke
          v-model:is-auto-revoked="isAutoRevoked"
          v-model:revoke="revoke"
          :revoke-date="revokeDate"
          :date-format="revokeDateFormat"
        />

        <div class="d-flex payment-actions">
          <FButton
            :loading="isPaymentCreating"
            size="small"
            @click="action({ notify: false })"
          >
            <AppIcon name="plus" size="18px" fill="var(--color-white)" />
            {{ t('merchantPayments.createPaymentModal.button.createPayment') }}
          </FButton>

          <AppText class="or-spacer">
            {{ t('common.or').toUpperCase() }}
          </AppText>

          <AppTooltip
            :is-enabled="isSelectedClientBlocked"
            text-align="left"
            width="252px"
          >
            <template #text>
              {{ t('merchantPayments.createPaymentModal.label.paymentCannotBeCreated') }}
            </template>

            <FButton
              :loading="isPaymentCreating"
              :disabled="isSelectedClientBlocked"
              size="small"
              class="notify-button"
              @click="action({ notify: true })"
            >
              <AppIcon name="plain" size="18px" />
              {{ t('merchantPayments.createPaymentModal.button.createPaymentAndNotify') }}
              <AppIcon
                v-if="isSelectedClientBlocked"
                name="info-circle-medium"
                :opacity="0.2"
                is-img-tag
                size="14px"
                style="margin-left: 8px;"
              />
            </FButton>
          </AppTooltip>
        </div>
      </template>
    </ActionForm>
  </SmoothModal>
</template>

<script setup>
import {
  computed, ref, watch,
} from 'vue';
import dayjs from 'dayjs';

import SelectClient from '@/components/Payment/SelectClient';
import AmountInput from '@/components/Payment/AmountInput.vue';
import SmoothModal from '@/components/SmoothModal';
import AppTooltip from '@/components/AppTooltip.vue';

import { currencyList } from '@/common/data';
import { ActionForm, useValidation, yup } from '@/validation';
import {
  baseAmountRule,
  emailRule,
  memoRule,
  requiredRule,
} from '@/validation/rules';
import { currency } from '@/composables/useCurrency';
import { useProfile } from '@/composables/useProfile';
import { addThousandsSeparators } from '@/utils/numberFormatter';
import { i18n } from '@/plugins/localization';
import { useNotifications } from '@/composables/useNotifications';

import { paymentsApi } from '../api';

import AutoRevoke from './AutoRevoke.vue';

const props = defineProps({
  clients: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update-data', 'get-clients']);

const isModalOpen = defineModel({ type: Boolean, default: false });

const closeModal = () => {
  isModalOpen.value = false;
  isAutoRevoked.value = false;
  shouldBeUserNotified.value = false;
  clientStatus.value = '';
};

const {
  generatePayment,
  sendPaymentRequest,
  createClient,
} = paymentsApi();

const shouldBeUserNotified = ref(false);

const isAutoRevoked = ref(false);
const revoke = ref('DAY');

const revokeDate = computed(() => {
  let params = [];
  if (revoke.value === 'HOUR') {
    params = [1, 'hour'];
  } else if (revoke.value === 'DAY') {
    params = [1, 'day'];
  } else if (revoke.value === 'THREE_DAYS') {
    params = [3, 'day'];
  } else if (revoke.value === 'WEEK') {
    params = [1, 'week'];
  } else if (revoke.value === 'MONTH') {
    params = [1, 'month'];
  }

  return dayjs().add(parseInt(params[0], 10), params[1]).format();
});

const { dateFormat, dateTimeFormat, numberFormat } = useProfile();
const revokeDateFormat = computed(() => (revoke.value === 'HOUR' ? dateTimeFormat.value : dateFormat.value));

const isPaymentCreating = ref(false);

const {
  fieldsErrors, clearState, initState, localizedErrors,
} = useValidation();

const fields = {
  clientEmail: '',
  clientName: '',
  baseAmount: null,
  autoRevoke: null,
  memo: '',
};

const defaultCurrency = computed(() => currencyList.find((el) => el.short === currency.value.value));
const minAmount = computed(() => addThousandsSeparators({
  val: defaultCurrency.value?.minAmount,
  separator: numberFormat.value,
}));

const validationSchema = yup.object().shape({
  clientEmail: emailRule,
  clientName: requiredRule,
  baseAmount: baseAmountRule(defaultCurrency.value?.minAmount, numberFormat.value, currency.value.value),
  memo: memoRule(),
});

const { requestFields } = initState(fields);

watch(computed(() => props.modelValue), () => {
  clearState();
  clientStatus.value = '';
  revoke.value = 'DAY';
});

const isNewClient = ref(false);
const onCreatePayment = async (params) => {
  const shouldPaymentBeCreated = ref(false);
  if (isNewClient.value && !fieldsErrors.value.length) {
    const { isSuccess, errorObject } = await createClient({
      email: requestFields.clientEmail,
      name: requestFields.clientName,
    });
    if (isSuccess) {
      emit('get-clients');
    }
    if (!isSuccess) {
      fieldsErrors.value = errorObject.fields;
    }
    shouldPaymentBeCreated.value = isSuccess;
  } else {
    shouldPaymentBeCreated.value = true;
  }
  if (shouldPaymentBeCreated.value) {
    requestFields.autoRevoke = isAutoRevoked.value ? revoke.value : null;
    isPaymentCreating.value = true;

    if (params?.notify) {
      onSendPayment();
    } else {
      onGeneratePayment();
    }
  }
};

const onGeneratePayment = async () => {
  const { isSuccess, errorObject } = await generatePayment(requestFields);
  requestHandler({ isSuccess, errorObject });
};

const clientStatus = ref('');
const { addNotification } = useNotifications();
const { t } = i18n.global;
const onSendPayment = async () => {
  emit('get-clients', null, async (responsedData) => {
    const selectedClient = responsedData.find((el) => el.clientEmail === requestFields.clientEmail);
    if (selectedClient.status === 'DO_NOT_DISTURB') {
      addNotification({ text: t('errors.clientHasDndEnabled'), config: { color: 'error' } });
      isPaymentCreating.value = false;
      clientStatus.value = 'DO_NOT_DISTURB';
    } else {
      const { isSuccess, errorObject } = await sendPaymentRequest(requestFields);
      requestHandler({ isSuccess, errorObject });
    }
  });
};

const onError = (errors) => {
  fieldsErrors.value = errors;
  Object.assign(fieldsErrors.value, errors);
};

const requestHandler = ({ isSuccess, errorObject }) => {
  isPaymentCreating.value = false;
  if (isSuccess) {
    emit('update-data');
    clearState();
    closeModal();
  } else {
    onError(errorObject.fields);
  }
};

const isSelectedClientBlocked = computed(() => {
  const client = props.clients.find((item) => item.clientEmail === requestFields.clientEmail);
  return ['DO_NOT_DISTURB', 'BLOCKED'].includes(client?.status) || false;
});

watch(computed(() => requestFields.clientEmail), () => {
  if (isSelectedClientBlocked.value) {
    shouldBeUserNotified.value = false;
  }
});
watch(isAutoRevoked, () => {
  if (!isAutoRevoked.value) {
    revoke.value = 'DAY';
  }
});

const onGetClients = (val) => {
  emit('get-clients', val);
};
</script>

<style scoped lang="scss">
.create-payment-form {
  :deep(.auto-revoke) {
    margin: 20px 0 30px;
  }

  .memo {
    margin-top: 20px;
  }

  .or-spacer {
    height: 40px;
    margin: 0 20px;
    color: var(--color-black-04);

    @include flex-center;
  }
}

:deep(.notify-button.is-disabled) {
  .icon path {
    fill: transparent !important;
    stroke: #c2c2c2 !important;
  }
}
</style>
