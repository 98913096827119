<template>
  <div class="auto-revoke">
    <div
      class="auto-revoke-action d-flex justify-content-between align-items-center"
      :class="{ 'is-hover': !isAutoRevoked }"
      @click.self="toggleIsAutoRevoked"
    >
      <div
        class="d-flex align-items-center"
        :class="{ 'is-hover': isAutoRevoked }"
        @click.stop="toggleIsAutoRevoked"
      >
        <FSwitch
          v-model="isAutoRevoked"
          is-small
          type="primary"
          class="mr-2"
          @change="(val) => isAutoRevoked = !val"
        />
        <AppText class="font-medium">
          {{ isAutoRevoked ? t('merchantPayments.createPaymentModal.label.autoRevokeIn') : t('merchantPayments.createPaymentModal.label.autoRevokeLink') }}
        </AppText>
      </div>
      <Transition name="show-fast">
        <div v-if="isAutoRevoked">
          <FRadio v-model="revoke" :options="periods" />
        </div>
      </Transition>
    </div>
    <Transition name="show-fast">
      <div v-if="isAutoRevoked" class="revoke-message">
        <i18n-t keypath="merchantPayments.createPaymentModal.label.autoRevokeDescription">
          <DateFormatter
            :key="dateFormat"
            :style="revoke === 'HOUR' ? 'white-space: nowrap' : ''"
            size="14px"
            :date="revokeDate"
            :format="dateFormat"
          />
        </i18n-t>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import DateFormatter from '@/components/Helpers/DateFormatter.vue';

import { revokePeriods } from '../data';

defineProps({
  revokeDate: {
    type: String,
    default: '',
  },
  dateFormat: {
    type: String,
    default: '',
  },
});

const isAutoRevoked = defineModel('isAutoRevoked', { type: Boolean, default: false });
const revoke = defineModel('revoke', { type: String, default: 'DAY' });

const toggleIsAutoRevoked = () => {
  isAutoRevoked.value = !isAutoRevoked.value;
};

const periods = revokePeriods.value.filter((period) => period.available);
</script>

<style scoped lang="scss">
.auto-revoke {
  border-radius: 4px;
  outline: 1px solid #E6E6E6;
}

.auto-revoke-action {
  padding: 9px 20px;
}

.revoke-message {
  background: #F7F7F7;
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid #E6E6E6;
}

.is-hover {
  cursor: pointer;
}
</style>
