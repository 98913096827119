<template>
  <TransitionWrapper>
    <div class="main-content-body">
      <FiltersHeader
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
        :statuses="statuses"
        :filters="filters"
      >
        <template #top-append>
          <AddButton
            :is-disabled="!isWalletConnected"
            :disabled-tooltip=" t('merchantPayments.label.disabledTooltip')"
            @click="isModalOpen = isWalletConnected"
          >
            {{ t('merchantPayments.label.createButton') }}
          </AddButton>
        </template>
      </FiltersHeader>

      <PaginationTable
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
        is-translation-disable
        class="payments-table"
        @row-click="onRowClick"
      />
    </div>
    <CreatePaymentModal
      v-model="isModalOpen"
      :clients="clients"
      @update-data="postProcessAfterCreation"
      @get-clients="onGetClients"
    />
    <PaymentModal
      v-model="isPaymentOpen"
      :payment="modalPayment"
      :clients="clients"
      @update-status="onStatusUpdate"
      @update:model-value="onToggleModal"
      @update="getData({ isFreshResponse: true })"
    />
  </TransitionWrapper>
</template>

<script>
import { ref } from 'vue';

import AddButton from '@/components/Buttons/AddButton.vue';
import FiltersHeader from '@/components/Modules/DataTable/FiltersHeader.vue';
import PaginationTable from '@/components/Modules/DataTable/PaginationTable.vue';
import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { getClients } from '@/api/clients';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';
import { MODULES_NAMES } from '@/common/modules';
import { subscribeToUpdateEmmiter, usePagination } from '@/composables/usePagination';
import { useWallet } from '@/composables/useWallet';
import { useTablesStore } from '@/store';

import CreatePaymentModal from './CreatePaymentModal';
import PaymentModal from './PaymentModal';
import { columns, filters, statuses } from './data';

export default {
  name: 'MerchantPayments',
  components: {
    FiltersHeader,
    PaginationTable,
    TransitionWrapper,
    CreatePaymentModal,
    PaymentModal,
    AddButton,
  },
  setup() {
    const moduleName = MODULES_NAMES.MERCHANT_PAYMENTS;
    const apiPath = '/merchants/payments';

    const { isWalletConnected } = useWallet();

    const { getData } = usePagination(apiPath, { moduleName, filters });

    getData();

    const isModalOpen = ref(false);

    useKeyboardEvent(keys.BACKQUOTE, () => { isModalOpen.value = isWalletConnected.value; });

    const isPaymentOpen = ref(false);
    const modalPayment = ref({});
    const onRowClick = (payment) => {
      modalPayment.value = payment;
      isPaymentOpen.value = true;
    };
    const onStatusUpdate = (data) => {
      modalPayment.value.status = data.status;
      modalPayment.value.state = data.state;
    };

    const clients = ref([]);
    const onGetClients = async (search, callback) => {
      await getClients({ isFreshResponse: true, shouldResetRequestState: true, search }).then(({ isSuccess, data }) => {
        if (isSuccess) {
          clients.value = data;
        }
        if (callback) {
          callback(data);
        }
      });
    };
    onGetClients();

    const onToggleModal = () => {
      modalPayment.value = {};
    };

    subscribeToUpdateEmmiter('update-payments-page', getData, onGetClients);

    const tableStore = useTablesStore();
    const postProcessAfterCreation = () => {
      tableStore.resetPageDateFilters(moduleName);
      setTimeout(() => {
        getData({ isFreshResponse: true });
      }, 0);
    };

    return {
      moduleName,
      apiPath,

      columns,
      statuses,
      filters,

      getData,

      isModalOpen,
      isWalletConnected,

      isPaymentOpen,
      onRowClick,
      modalPayment,

      clients,
      onGetClients,
      onToggleModal,
      onStatusUpdate,
      postProcessAfterCreation,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.table__body .table__row__cell) {
  cursor: pointer;
}
</style>
